import React, { useState, useEffect } from 'react';

function Commissioner() {
  const [enteredPassword, setEnteredPassword] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const fileInputRef = React.useRef(null);

  // Hard-coded password (not secure, just for demonstration)
  const CORRECT_PASSWORD = 'HBS2F6xI';

  // ============== 1) Password Handling ==============
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (enteredPassword === CORRECT_PASSWORD) {
      setIsAuthorized(true);
    } else {
      alert('Incorrect password!');
    }
  };

  // ============== 2) File Upload ==============
  const handleFileUpload = async (e) => {
    const files = e.target.files;
    const csvFiles = [...files].filter(file => file.name.endsWith('.csv'));

    if (csvFiles.length !== files.length) {
      alert('Some files are not CSV! Only CSV files are accepted.');
    }

    // Create a FormData object
    const formData = new FormData();
    csvFiles.forEach(file => {
      formData.append('csvFiles', file);
    });

    try {
      setIsUploading(true); // show loading indicator
      const response = await fetch('/api/upload/fihl', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Upload request failed');
      }

      alert('Upload complete!');
      // Reset file input so user can pick again
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; 
      }

      // Optionally refresh the file list after upload
      loadFileList();

    } catch (error) {
      console.error('Error uploading files:', error);
      alert('There was an error uploading your files');
    } finally {
      setIsUploading(false); // hide loading indicator
    }
  };

  // ============== 3) File List Table ==============
  const loadFileList = async () => {
    try {
      const response = await fetch('/api/files/fihl');
      if (!response.ok) {
        throw new Error('Failed to fetch file list');
      }
      const data = await response.json();
      setFileList(data.files || []);
    } catch (err) {
      console.error('Error loading file list:', err);
    }
  };

  // Load the file list when authorized
  useEffect(() => {
    if (isAuthorized) {
      loadFileList();
    }
  }, [isAuthorized]);

  // Helper to format date/time nicely
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const d = new Date(dateString);
    return d.toLocaleString(); // e.g. "1/25/2025, 7:45:12 PM"
  };

  // ============== 4) Center the UI with basic styling ==============
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2rem',
  };

  const tableStyle = {
    borderCollapse: 'collapse',
    marginTop: '1rem',
  };

  const thTdStyle = {
    border: '1px solid #ccc',
    padding: '0.5rem 1rem',
    textAlign: 'left',
  };

  return (
    <div style={containerStyle}>
      <h2>Commissioner Portal</h2>

      {!isAuthorized ? (
        /* Render the password form */
        <form onSubmit={handlePasswordSubmit} style={{ margin: '2em 0' }}>
          <label>
            Enter Password:
            <input
              type="password"
              value={enteredPassword}
              onChange={(e) => setEnteredPassword(e.target.value)}
              style={{ marginLeft: '1em' }}
            />
          </label>
          <button type="submit" style={{ marginLeft: '1em' }}>Unlock</button>
        </form>
      ) : (
        /* If authorized, show file upload + table */
        <div style={{ textAlign: 'center' }}>
          <p>Welcome, Commissioner!</p>

          {/* (1) Upload UI */}
          <div>
            {/* Show message/spinner if uploading */}
            {isUploading && <div style={{ color: 'red' }}>Please wait... Uploading...</div>}

            <label>
              Upload CSV(s):
              <input
                type="file"
                accept=".csv"
                multiple
                onChange={handleFileUpload}
                ref={fileInputRef}
                style={{ marginLeft: '1em' }}
              />
            </label>
          </div>

          {/* (2) Table of Files */}
          <br />
          <br />
          <button onClick={loadFileList} style={{ margin: '1em' }}>Refresh File List</button>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thTdStyle}>File Name</th>
                <th style={thTdStyle}>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              {fileList.map((file) => (
                <tr key={file.name}>
                  <td style={thTdStyle}>{file.name}</td>
                  <td style={thTdStyle}>{formatDate(file.lastUpdated)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Commissioner;
